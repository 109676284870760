* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.meter {
  padding-top: 6rem !important;
  /* display: flex;
  justify-content: center; */
  text-align: center;
}
.field {
  margin-top: 3rem;
}
.desktop {
  width: 100%;
  max-height: 100vh;
  margin-top: 5rem;
}
.filter {
  margin: 5rem 0 2rem 0;
}
.login {
  width: 40%;
  margin: 5rem auto;
  border: 0.1rem solid #1565c0 !important;
}
.top-table {
  /* width: 90%; */
  margin: 5rem auto;
  display: flex;
  /* justify-content: space-between; */
}
.preview {
  padding-top: 3rem;
  padding-left: 3rem;
}
.logo {
  width: 5%;
}
.logo-text {
  padding-left: 0.2rem;
  padding-top: 5rem;
  font-size: 1.5rem;
  font-weight: bold;
}


@media screen and (max-width: 900px) {
  .filter {
    margin: 5rem 20% 2rem;
  }
  .login {
    width: 80%;
  }
  .logo {
    width: 7%;
  }
  .logo-text {
    padding-left: 0.2rem;
    padding-top: 2rem;
    font-size: 1rem;
    font-weight: bold;
  }
  .capex-due {
    text-decoration: none;
    background-color: #1565c0 ;
    padding : 0.5rem 1rem;
    border-radius: 0.4rem;
    color : white;
    cursor : pointer;
 

    
    }
}

